export default defineAppConfig({
  ui: {
    primary: "blue-violet",
    gray: "neutral",
    notifications: {
      position: "top-0 bottom-auto",
    },
    variables: {
      header: {
        height: "3rem",
      },
    },
  },
});
